export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('clickout', {
    beforeMount(el, binding) {
      document.addEventListener(
        'click',
        (e) => {
          !el.contains(e.target) && binding.value()
        },
        false
      )
    },
    unmounted() {
      document.removeEventListener('click', () => {})
    }
  })
  nuxtApp.vueApp.directive('scrollend', {
    beforeMount(el, binding) {
      nextTick().then(() => {
        if (el.scrollHeight <= el.offsetHeight) {
          binding.value(false)
        }
        el.addEventListener('scroll', () => {
          if (el.scrollTop + 10 >= el.scrollHeight - el.offsetHeight) {
            binding.value(false)
          } else {
            binding.value(true)
          }
        })
      })
    },
    unmounted(el) {
      el.removeEventListener('scroll', () => {})
    }
  })
})
