export const appBaseURL = "/"

export const appBuildAssetsDir = "/createofficial/"

export const appCdnURL = "https://dl.dir.freefiremobile.com/common/web_event/"

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, minimum-scale=1.0, maximum-scale=1.0"},{"name":"description","content":""},{"name":"keywords","content":""}],"link":[{"rel":"icon","type":"image/x-icon","href":""}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"en"}}

export const appPageTransition = {"name":"page","mode":"out-in"}

export const appLayoutTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false