import { defineStore } from 'pinia'
import type { BaseApiSEO, ConfigApiData, ConfigInfoApi } from '~/server/types'
export interface CONFIG extends ConfigApiData {
  id?: number // 作为lang_id参数
}
export interface DOWNLOAD_CONFIG {
  name: string
  url: string
  status: boolean
  popup: {
    title: string
    desc: string
    status: boolean
    url: string
  }
}
export interface ACCESS_TIPS_CONFIG {
  redirect_url: string
  popup: {
    title: string
    desc: string
    status: boolean
    url: string
  }
}
export const useStore = defineStore('Config', () => {
  const config = ref<CONFIG>({} as CONFIG)
  const downloadInfo = ref<DOWNLOAD_CONFIG>({} as DOWNLOAD_CONFIG)
  const accessTipsInfo = ref<ACCESS_TIPS_CONFIG>({} as ACCESS_TIPS_CONFIG)
  const downloadPopupShow = ref(false)
  const accessTipsPopupShow = ref(false)
  async function fetchConfig() {
    const route = useRoute()
    const lang = route.params.lang || ''
    const res = await useFetch(`/api/config?lang=${lang}`)
    const data = (res.data.value as ConfigInfoApi).data
    setConfig(data)
  }
  function setConfig(data: ConfigApiData) {
    config.value = data
    config.value.id = config.value.lang_list.find((item) => {
      return item.region === 'GLOBAL'
        ? item.lang === config.value.region_lang
        : `${item.region}_${item.lang}` === config.value.region_lang
    })?.id
    setDownloadInfo(config.value)
    setAccessTipsInfo(config.value)
    setAccessTipsPopupShow(config.value)
  }
  function setDownloadInfo(config: CONFIG) {
    downloadInfo.value = {
      name: config.footer.studio_download_name,
      url: config.footer.studio_download_url,
      status: Boolean(config.footer.studio_download_status),
      popup: {
        title: config.footer.download_popup_title,
        desc: config.footer.download_popup_desc,
        status: Boolean(config.footer.download_popup_status),
        url: config.footer.studio_download_url
      }
    }
  }
  function setAccessTipsInfo(config: CONFIG) {
    accessTipsInfo.value = {
      redirect_url: config?.redirect_url,
      popup: {
        title: config.footer.popup_accesstips_title,
        desc: config.footer.popup_accesstips_desc,
        status: Boolean(config.footer.popup_accesstips_status),
        url: config?.redirect_url
      }
    }
  }
  function setDownloadPopupShow(status: boolean) {
    downloadPopupShow.value = status
  }
  function setDownloadPopupUrl(url: string) {
    downloadInfo.value.popup.url = url
  }
  function setAccessTipsPopupShow(config: CONFIG): void {
    accessTipsPopupShow.value = !!config?.redirect_url
  }
  function updateSEO(seo?: BaseApiSEO) {
    if (!seo) {
      seo = config.value.footer.seo
    }
    if (seo) {
      useHead({
        htmlAttrs: {
          'data-lang': config.value.region_lang
        },
        title: seo.title,
        meta: [
          {
            name: 'description',
            content: seo.description
          },
          {
            name: 'keywords',
            content: seo.keywords
          }
        ]
      })
    }
  }
  function formatTransify(key: string) {
    return config.value.transify[key] || key
  }
  return {
    config,
    downloadInfo,
    accessTipsInfo,
    downloadPopupShow,
    accessTipsPopupShow,
    setDownloadPopupShow,
    setDownloadPopupUrl,
    setAccessTipsPopupShow,
    fetchConfig,
    updateSEO,
    formatTransify
  }
})
