import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const { public: config } = useRuntimeConfig()
  const { env } = config
  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: env.VITE_SENTRY_DSN_PUBLIC,
    environment: process.env.NODE_ENV,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    // tracePropagationTargets: [''],
    tracesSampleRate: 0.1
  })
})
