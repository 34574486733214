import { default as _91id_93mFXpUL1YMnMeta } from "/var/www/pages/[lang]/article/[id].vue?macro=true";
import { default as _91id_938P7CZm7dSrMeta } from "/var/www/pages/[lang]/docs/[id].vue?macro=true";
import { default as docsnCL4SGBVzdMeta } from "/var/www/pages/[lang]/docs.vue?macro=true";
import { default as index8w8T3TYZXUMeta } from "/var/www/pages/[lang]/index.vue?macro=true";
import { default as indexi8HxWgZGx5Meta } from "/var/www/pages/[lang]/news/index.vue?macro=true";
import { default as indexhpdXlba06NMeta } from "/var/www/pages/[lang]/patch-notes/index.vue?macro=true";
import { default as _91id_93DkUGprTTclMeta } from "/var/www/pages/[lang]/tutorial/[id].vue?macro=true";
import { default as indexlBzwXQ4GaVMeta } from "/var/www/pages/[lang]/tutorial/index.vue?macro=true";
import { default as indexTNebhwOASsMeta } from "/var/www/pages/index.vue?macro=true";
import { default as healthzUJyHLXiUK6Meta } from "/var/www/pages/manage/healthz.vue?macro=true";
export default [
  {
    name: _91id_93mFXpUL1YMnMeta?.name ?? "lang-article-id",
    path: _91id_93mFXpUL1YMnMeta?.path ?? "/:lang()/article/:id()",
    meta: _91id_93mFXpUL1YMnMeta || {},
    alias: _91id_93mFXpUL1YMnMeta?.alias || [],
    redirect: _91id_93mFXpUL1YMnMeta?.redirect,
    component: () => import("/var/www/pages/[lang]/article/[id].vue").then(m => m.default || m)
  },
  {
    name: docsnCL4SGBVzdMeta?.name ?? "lang-docs",
    path: docsnCL4SGBVzdMeta?.path ?? "/:lang()/docs",
    meta: docsnCL4SGBVzdMeta || {},
    alias: docsnCL4SGBVzdMeta?.alias || [],
    redirect: docsnCL4SGBVzdMeta?.redirect,
    component: () => import("/var/www/pages/[lang]/docs.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_938P7CZm7dSrMeta?.name ?? "lang-docs-id",
    path: _91id_938P7CZm7dSrMeta?.path ?? ":id()",
    meta: _91id_938P7CZm7dSrMeta || {},
    alias: _91id_938P7CZm7dSrMeta?.alias || [],
    redirect: _91id_938P7CZm7dSrMeta?.redirect,
    component: () => import("/var/www/pages/[lang]/docs/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: index8w8T3TYZXUMeta?.name ?? "lang",
    path: index8w8T3TYZXUMeta?.path ?? "/:lang()",
    meta: index8w8T3TYZXUMeta || {},
    alias: index8w8T3TYZXUMeta?.alias || [],
    redirect: index8w8T3TYZXUMeta?.redirect,
    component: () => import("/var/www/pages/[lang]/index.vue").then(m => m.default || m)
  },
  {
    name: indexi8HxWgZGx5Meta?.name ?? "lang-news",
    path: indexi8HxWgZGx5Meta?.path ?? "/:lang()/news",
    meta: indexi8HxWgZGx5Meta || {},
    alias: indexi8HxWgZGx5Meta?.alias || [],
    redirect: indexi8HxWgZGx5Meta?.redirect,
    component: () => import("/var/www/pages/[lang]/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexhpdXlba06NMeta?.name ?? "lang-patch-notes",
    path: indexhpdXlba06NMeta?.path ?? "/:lang()/patch-notes",
    meta: indexhpdXlba06NMeta || {},
    alias: indexhpdXlba06NMeta?.alias || [],
    redirect: indexhpdXlba06NMeta?.redirect,
    component: () => import("/var/www/pages/[lang]/patch-notes/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93DkUGprTTclMeta?.name ?? "lang-tutorial-id",
    path: _91id_93DkUGprTTclMeta?.path ?? "/:lang()/tutorial/:id()",
    meta: _91id_93DkUGprTTclMeta || {},
    alias: _91id_93DkUGprTTclMeta?.alias || [],
    redirect: _91id_93DkUGprTTclMeta?.redirect,
    component: () => import("/var/www/pages/[lang]/tutorial/[id].vue").then(m => m.default || m)
  },
  {
    name: indexlBzwXQ4GaVMeta?.name ?? "lang-tutorial",
    path: indexlBzwXQ4GaVMeta?.path ?? "/:lang()/tutorial",
    meta: indexlBzwXQ4GaVMeta || {},
    alias: indexlBzwXQ4GaVMeta?.alias || [],
    redirect: indexlBzwXQ4GaVMeta?.redirect,
    component: () => import("/var/www/pages/[lang]/tutorial/index.vue").then(m => m.default || m)
  },
  {
    name: indexTNebhwOASsMeta?.name ?? "index",
    path: indexTNebhwOASsMeta?.path ?? "/",
    meta: indexTNebhwOASsMeta || {},
    alias: indexTNebhwOASsMeta?.alias || [],
    redirect: indexTNebhwOASsMeta?.redirect,
    component: () => import("/var/www/pages/index.vue").then(m => m.default || m)
  },
  {
    name: healthzUJyHLXiUK6Meta?.name ?? "manage-healthz",
    path: healthzUJyHLXiUK6Meta?.path ?? "/manage/healthz",
    meta: healthzUJyHLXiUK6Meta || {},
    alias: healthzUJyHLXiUK6Meta?.alias || [],
    redirect: healthzUJyHLXiUK6Meta?.redirect,
    component: () => import("/var/www/pages/manage/healthz.vue").then(m => m.default || m)
  }
]