<template>
  <section class="error">
    <img class="error-img" src="@/assets/images/error.png" :alt="error.statusCode" />
  </section>
</template>
<script lang="ts" setup>
const error = useError()
</script>
<style lang="scss" scoped>
.error {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #202227;
  background-size: cover;
  background-image: url('@/assets/images/error-bg-m.jpg');
}
.error-img {
  max-width: 50%;
}
@media screen and (min-width: 1024px) {
  .error {
    background-image: url('@/assets/images/error-bg.jpg');
  }
  .error-img {
    max-width: 22.22%;
  }
}
</style>
